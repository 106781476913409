<template>
  <div class="sovWrap_category">
    <div class="container">
      <div class="sovTitle">
        <div class="filter">
          <div>
            <span style="margin-right: 10px">时间范围</span>
            <el-date-picker
                v-model="aggMonth"
                type="month"
                unlink-panels
                :clearable="false"
                value-format="YYYY-MM"
                @change="changeParams"
                :disabledDate="disabledDate"
            >
            </el-date-picker>
          </div>
          <div>
            <span style="margin-left: 40px; margin-right: 10px">一级维度</span>
            <el-select
                size="mini"
                class="selectBrandClass"
                filterable
                collapse-tags
                multiple
                @change="changeParams"
                v-model="firstDimension"
            >
              <el-option
                  v-for="(item,index) in firstDimensionList"
                  :key="index"
                  :label="item"
                  :value="item"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="flex_block" style="align-items: center">
          <p
              @click="expandAllFunc()"
              :class="['expandClass', { isExpandClass: isExpand }]"
          >
            <el-icon v-if="!isExpand" ><Plus /></el-icon>
            <el-icon v-else><Minus /></el-icon>
            <span v-if="!isExpand">全部展开</span>
            <span v-else>全部收起</span>
          </p>
          <el-radio-group text-color="#fff" v-model="category" @change="changeParams" size="mini">
            <el-radio-button
                v-for="(item,index) in categoryList"
                :key="index"
                :label="item"
            >{{ item }}
            </el-radio-button>
          </el-radio-group>
          <switch-component ref="switchRef" @switch="switchChange()"/>
        </div>
      </div>
    </div>
    <div class="table">
      <div class="tableMain">
        <el-table
            ref="treeTable"
            v-loading="brandListTableLoading"
            :data="tableData"
            @header-click="headerClickHandle"
            @select="selectFuncHandle"
            :expand-row-keys="expandFunc"
            :row-key="getRowKey"
            :header-cell-style="getRowClass"
        >
          <el-table-column type="expand">
            <template  #default="{row}">
              <el-table class="inline_table" :data="row.sovCategoryProductInfoListVO" border="true">
                <el-table-column align="center" label="产品" prop="productName" >
                  <template #default="{ row }">
                    <img class="new_icon" v-if="row.isNew === 1" src="./new_icon.svg"/>
                    {{ row.productName }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="经典卖点话术" prop="salesmanship" />
                <el-table-column align="center" label="曝光量" prop="exposure" >
                  <template #default="{ row }">
                    {{ sliceDecimal(row.exposure) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" label="较上月排名变化" prop="rankTrend" >
                  <template #default="{ row }">
                    <div class="flex_block">
                      <span class="num">
                      {{ rankTrend(row.rankTrend) }}
                      </span>
                      <span class="arrow-up" v-if="row.rankTrend > 0"></span>
                      <span class="arrow-down" v-else-if="row.rankTrend <0"></span>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-icon class="add_icon" @click="showMore(row)" v-if="row.sovCategoryProductInfoListVO.length === 6 && row.sovCategoryProductInfoListVO[5].productName === '--' "><CirclePlusFilled /></el-icon>
            </template>
          </el-table-column>

          <el-table-column prop="rank" align="center" width="60" label="排名" >
          </el-table-column>
          <el-table-column prop="category" label="品类" align="center">
          </el-table-column>
          <el-table-column
              prop="firstDimension"
              label="一级维度"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="secondDimension"
              align="center"
              label="二级维度"
          >

          </el-table-column>
          <el-table-column
              prop="productSellingPoint"
              align="center"
              label="产品卖点"
          >
            <template #default="{ row }">
              <img class="new_icon" v-if="row.isNew === 1" src="./new_icon.svg"/>
              {{ row.productSellingPoint }}
            </template>
          </el-table-column>
          <el-table-column
              prop="exposure"
              align="center"
              label="曝光量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.exposure) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="engagement"
              align="center"
              label="互动量"
          >
            <template #default="{ row }">
              {{ sliceDecimal(row.engagement) }}
            </template>
          </el-table-column>
          <el-table-column
              prop="rankTrend"
              align="center"
              label="较上月排名变化"
          >
            <template #default="{ row }">
              <div class="flex_block">
                <span class="num">
                {{ rankTrend(row.rankTrend) }}
                </span>
                <span class="arrow-up" v-if="row.rankTrend > 0"></span>
                <span class="arrow-down" v-else-if="row.rankTrend <0"></span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination ">
        <!--        <span class="dataTime">-->
        <!--              * 统计数据时间为：{{-->
        <!--             month.month.slice(0, 4) + "-" + month.month.slice(4)-->
        <!--          }}-->
        <!--            </span>-->
        <span class="dataTime">
              * 统计数据时间为：{{
            timeRangeArr[0]
          }}
            </span>
        <el-pagination
            background
            :current-page="currentPage"
            @current-change="nextPage"
            layout="prev, pager, next"
            :page-size="size"
            :total="dataTotal"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog title="提示" v-model="dialogVisible" width="30%">
      <span
      >如果您正在关注的品牌暂时不在榜单内，请联系邮件地址：omnivoice@meritco-group.com以添加您需要新增的品牌</span
      >
      <template #footer>
        <el-button type="primary" @click="dialogVisible = false"
        >确 定
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { listPageBaseInfo,getCategoryRanklist } from "@/api/sov/sovCategory";
import {mapState, mapMutations, mapGetters} from "vuex";
import { CirclePlusFilled,Plus,Minus } from "@element-plus/icons-vue";
import switchComponent from './categoryComponents/switchComponent.vue'

export default {
  data() {
    return {
      // 分页数据
      page: 1,
      size: 20,
      dataTotal: 0,
      // 头部数据
      aggMonth: "",
      selBrand: [],
      inputBrand: "",
      // 可选品牌list
      chooseBrandList: [],
      value:0,
      // 活动时间

      brandListTableLoading: false,
      getRowClass: {
        color: "#8C98A5",
      },
      categoryList: [],
      tabType: "",
      tableData: [],
      expandAll: false,
      isExpand: false,
      brandList: [],
      brandNameList: [],
      brandListId: [],
      dialogVisible: false,
      currentPage: 1,
      sortColumn: "exposurePct",
      sortDirection: "desc",
      isExposurSort: 2,
      isSgSort: 0,
      isSrSort: 0,
      time: "",
      tempTime: "",
      timeRangeArr:[],
      firstDimension:'',
      firstDimensionList:[],
      category:'',
      tableDataBak: [],
      isNew:0
    };
  },
  mounted() {
    this.getBaseInfo()
    // this.getCategoryMonth();
    // this.init();
  },
  components: {
    switchComponent,
    CirclePlusFilled,
    Minus,
    Plus
  },
  computed: {
    ...mapState(["sovCatePageSave"]),
    ...mapGetters([
      "month",
    ]),
    expandFunc() {
      if (!this.expandAll) {
        return [];
      } else {
        return this.tableData.map((v) => {
          return v.id;
        });
      }
    },
  },
  methods: {
    ...mapMutations({
      setCatePageSave: "SET_SOVCATEPAGESAVE",
    }),
    async getBaseInfo (){
      const res = await listPageBaseInfo()
      if(res.code === 0){
        this.timeRangeArr = res.data.monthAggList
        this.aggMonth = res.data.monthAggList[0]
        this.firstDimensionList = res.data.firstDimensionList
        this.categoryList = [...res.data.categoryList]
        this.category = this.categoryList[0]
        await this.init()
      }
    },
    disabledDate(time) {
      if (this.timeRangeArr.length > 1) {
        return (
            time.getTime() <
            new Date(
                this.timeRangeArr[this.timeRangeArr.length - 1]
            ).getTime() ||
            time.getTime() > new Date(this.timeRangeArr[0]).getTime()
        );
      } else if (this.timeRangeArr.length < 2) {
        return (
            `${time.getFullYear()}-${time.getMonth()}` !==
            `${new Date(this.timeRangeArr[0]).getFullYear()}-${new Date(
                this.timeRangeArr[0]
            ).getMonth()}`
        );
      } else {
        return true;
      }
    },
    async init() {
      await this.getCategoryRanklist();
    },
    async getCategoryRanklist() {
      this.isExpand = false
      this.brandListTableLoading = true;
      let res = await getCategoryRanklist({
        firstDimensions: this.firstDimension,
        category: this.category,
        aggMonth: this.aggMonth,
        pageNo: this.currentPage,
        pageSize: this.size,
        isNew:this.isNew === 0?"":1
      });
      if (res.code === 0) {
        this.tableData = JSON.parse( JSON.stringify(res.data.table));
        this.tableDataBak = JSON.parse( JSON.stringify(res.data.table));
        this.dataTotal = res.data.total;
        if(this.tableData && this.tableData.length > 0){
          this.tableData.forEach(item =>{
            if(item.sovCategoryProductInfoListVO.length > 5){
              item.sovCategoryProductInfoListVO = item.sovCategoryProductInfoListVO.slice(0,5)
              item.sovCategoryProductInfoListVO.push({
                productName:'--',
                salesmanship:'--',
                exposure:'--',
                rankTrend:null,
              })
            }
          })
        }

      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      this.brandListTableLoading = false;
    },
    async getBrand() {
      let res = await getBrand();
      if (res.code === 0) {
        this.chooseBrandList = this.formatBrandList(res.data);
      } else if (res.code !== 123) {
        this.$message.error("获取数据失败，请联系管理员!");
      }
    },
    formatBrandList(data) {
      let arr = [];
      data.forEach((v) => {
        arr.push({
          label: v.brand,
          value: v.brandId,
        });
      });
      return arr;
    },
    headerClickHandle(column) {
      if (
          column.property === "exposurePct" ||
          column.property === "exposureGrowth" ||
          column.property === "sg"
      ) {
        this.sortColumn = column.property;
        this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
        this.getCategoryRanklist();
        if (this.sortColumn === "exposurePct") {
          this.isSgSort = 0;
          this.isSrSort = 0;
          this.isExposurSort = this.sortDirection === "desc" ? 2 : 1;
        } else if (column.property === "exposureGrowth") {
          this.isExposurSort = 0;
          this.isSgSort = 0;
          this.isSrSort = this.sortDirection === "desc" ? 2 : 1;
        } else {
          this.isExposurSort = 0;
          this.isSrSort = 0;
          this.isSgSort = this.sortDirection === "desc" ? 2 : 1;
        }
      }
    },
    getRowKey(row) {
      return row.id;
    },
    expandAllFunc() {
      this.isExpand = !this.isExpand
      this.tableData.forEach((item) => {
        this.$refs.treeTable.toggleRowExpansion(item, this.isExpand);
      });
    },
    brandCompare() {
      if (this.brandListId.length < 2) {
        this.$message.info({
          type: "info",
          message: "请至少选择两个品牌去对比",
        });
      } else if (this.brandListId.length > 3) {
        this.$message.info({
          type: "info",
          message: "最多选择3个品牌做对比",
        });
      } else {
        const query = {
          categories: this.btoa(this.brandListId.toString()),
          categoryNameList: encodeURI(JSON.stringify(this.brandNameList)),
          duration: this.duration,
        };
        this.$router.push({ name: "sovCategoryCompare", query });
      }
    },
    selectFuncHandle(item) {
      const temp = [];
      this.brandList = item;
      item.forEach((v) => {
        temp.push({
          label: v.category,
          value: v.id,
        });
      });
      this.brandNameList = temp;
      this.brandListId = item.map((v) => v.id);
    },
    toBrand(row) {
      const query = {
        category: encodeURI(row.category),
        categoryId: this.btoa(row.id),
        duration: this.duration,
      };
      this.$router.push({ name: "sovCategory", query });
      this.setCatePageSave(this.page);
    },
    async nextPage(page) {
      this.page = page;
      this.currentPage = page;
      await this.getCategoryRanklist();
      this.brandList.forEach((row) => {
        this.$refs["multipleTable"].toggleRowSelection(row);
      });
    },
    newBrand() {
      this.dialogVisible = true;
    },
    disabledSelect(row) {
      if (this.brandListId.includes(row.id)) {
        return true;
      } else if (this.brandListId.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    rankTrend(num){
      if(num === null || num === 0){
        return '--'
      }
      return Math.abs(num)
    },
    showMore(row){
      this.tableData.forEach(item =>{
        if(item.id === row.id){
          item.sovCategoryProductInfoListVO = this.tableDataBak.find(i => i.id === row.id).sovCategoryProductInfoListVO
        }
      })
    },
    switchChange(){
      if(!this.$refs.switchRef.isChecked) {
        this.isNew = 1
      } else{
        this.isNew = 0
      }
      this.changeParams()
    },
    async changeParams(){
      this.currentPage = 1
      this.isExpand = false
      await this.init()
    }
  },
  watch: {
    $route: {
      handler() {
        this.currentPage = this.sovCatePageSave;
        this.page = this.sovCatePageSave;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.sovWrap_category {
  width: 100%;
  height: 100%;

  .container {
    font-size: 16px;
    position: relative;
    top: -10px;
    background-color: #fff;
    height: 62px;
    line-height: 60px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    padding: 0 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .sovTitle {
      display: flex;
      align-items: center;

      .filter {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .selectClass {
          width: 122px;
        }

        .selectBrandClass {
          width: 260px;
        }
      }

      .newBrand {
        color: #fff;
        width: 92px;
        padding: 0;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;

        .iClass {
          margin-left: 6px;
        }
      }
    }
  }

  .table {
    padding: 20px 20px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .tab {
      position: relative;
      height: 30px;

      .spanTimeStyle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      .abClass {
        position: absolute;
        left: 13px;
        bottom: -54px;
        z-index: 900;
        font-size: 14px;
        color: #8c98a5;

        .spanClass {
          display: inline-block;
          width: 61px;
          height: 28px;
          color: #fff;
          line-height: 28px;
          text-align: center;
          background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          margin-left: 5px;
        }
      }
    }

    .tableMain {
      margin-top: 16px;

      .expandClass {
        width: 92px;
        height: 28px;
        display: inline-block;
        line-height: 28px;
        text-align: left;
        padding-left: 8px;
        background: #f4f3f3;
        border-radius: 4px;
        cursor: pointer;

        i {
          margin-left: 10px;
        }
      }

      .isExpandClass {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
        color: #fff;
      }

      .paddingClass {
        padding: 8px 0;

        span {
          display: inline-block;
          text-align: center;
        }
      }

      .borderClass {
        border-bottom: 1px solid #e7e7e7;
      }
    }

    .pagination {
      text-align: right;
      margin-top: 10px;
    }
  }
}
</style>

<style lang="scss">
.sovWrap {
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-color: transparent;
    box-shadow: -1px 0 0 0 transparent;
  }

  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }

  thead .el-table-column--selection .cell {
    display: none;
  }

  .el-table
  .el-table__header-wrapper
  .el-table__header
  thead
  > tr
  > th
  > .cell {
    color: #8c98a5;
    font-size: 14px;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #e09a29;
    border-color: #e09a29;
  }

  .el-checkbox__inner.is-focus .el-checkbox__inner {
    border-color: #e09a29;
  }

  .el-checkbox__inner:hover {
    border-color: #e09a29;
  }

  .el-table__expanded-cell {
    background-color: #f9fcff;
    padding: 0 !important;
    padding-left: 140px !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #e09a29;
    color: #fff !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #e09a29;
  }

  .iStyle {
    position: absolute;
    color: #c0c4cc;
  }

  .iblue {
    color: #409eff;
  }

  .ibottom {
    top: 9px;
  }

  .itop {
    top: 2px;
  }
}
.flex_block{
  display: flex;
  gap:12px;
  justify-content: center;
}
.arrow-up,.arrow-down{
  position: relative;
  width: 0;
  height: 0;
}
.arrow-up::before{
  content: '';
  position: absolute;
  top: -4px;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: rgb(192,0,0); /* 修改这里的颜色和尺寸以调整三角形 */
  transform: translateX(-50%);
}
.arrow-down::before{
  content: '';
  position: absolute;
  top: 4px;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: rgb(112,173,71); /* 修改这里的颜色和尺寸以调整三角形 */
  transform: translateX(-50%);
}
.sovWrap .el-table__expanded-cell{
  padding:10px!important;
}
.add_icon{
  position: absolute;
  font-size: 24px;
  color:rgb(217,217,217);
  bottom:0;
  z-index:9;
  cursor:pointer;
  left:50%;
  margin-left:-12px;
}
.expandClass {
  font-size:14px;
  cursor: pointer;
  display: flex;
  align-items: center;

}
.new_icon{
  width:24px;
  vertical-align: middle;
}
.el-radio-button__original-radio:checked+.el-radio-button__inner, .el-pagination.is-background .el-pager li.is-active{
  background-color:#4f79bf;
  border-color:#4f79bf;
}
.pagination{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pagination .dataTime {
  font-size: $font-size-medium;
  line-height: 30px;
  color: $color-sub-title;
}
</style>
